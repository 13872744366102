import serializeAction from './base';

function normalizePrice(price) {
  return price / 100;
}

function serializeCartItem(item) {
  let shopProductCategory;
  if (item.category === 'SoloDevice') {
    shopProductCategory = 'endgeraet';
  } else {
    shopProductCategory = 'zubehoer';
  }
  return {
    shopProductCategory,
    primaryCategory: item.primaryCategory,
    familyName: item.familyName,
    name: item.name,
    bundle: item.gtins && item.gtins.length > 1,
    articleNumber: item.articleNumber,
    eans: item.gtins || item.product.eans,
    quantity: item.quantity,
    price: normalizePrice(item.price),
  };
}

export default function serializeCartAction(action, cartItems, isKnownCustomer) {
  const items = (cartItems || []).map(serializeCartItem);
  return serializeAction({
    action,
    customerIdentified: isKnownCustomer,
    items,
  });
}
