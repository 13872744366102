<template>
  <div class="count-badge">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'CountBadge',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

.count-badge {
  @include font-size(18px, 21px);
  background-color: #1063ad;
  color: color(white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 3px;
  font-weight: 900;
}
</style>
