<template>
  <AddToCartButton
    button-text="In den Warenkorb"
    :display-text="displayText"
    :display-icon="displayIcon"
    theme="default"
    :disabled="isDisabled"
    class="add-to-cart-button-container"
    @add-to-cart="addOfferToCart"
  >
    <template #badge>
      <CountBadge
        v-show="hasQuantityBadge"
        :value="cartQuantity"
        class="add-to-cart-button-container__count"
        theme="default"
      />
    </template>
  </AddToCartButton>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import serializeCartAction from '@/lib/tealium/serializers/cart';
import getStockFromOffer from '@/lib/stock-from-offer';
import CountBadge from '@/components/count-badge.vue';
import { AddToCartButton } from '@i22-td-smarthome/component-library';
import TrackAction from '@/mixins/track-action';

export default {
  name: 'AddToCartButtonContainer',
  components: {
    AddToCartButton,
    CountBadge,
  },
  mixins: [TrackAction],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
      validator: (value) => value >= 1,
    },
    displayIcon: {
      type: Boolean,
      default: true,
    },
    displayText: {
      type: Boolean,
      default: true,
    },
    displayCartQuantity: {
      type: Boolean,
      default: false,
    },
    tealiumSuffix: {
      type: String,
      default: null,
    },
  },
  emits: ['added', 'error', 'click'],
  data() {
    return {
      isSubmitting: false,
      stockQuantity: getStockFromOffer(this.offer),
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItems', 'cartInitialized']),
    articleNumber() {
      return this.offer?.product?.articleNumber || null;
    },
    contractNumber() {
      return this.offer?.contract?.contractNumber || null;
    },
    cartItem() {
      return this.cartItems.find(
        (item) =>
          (item.articleNumber && item.articleNumber === this.articleNumber) ||
          (item.contractNumber && item.contractNumber === this.contractNumber)
      );
    },
    cartQuantity() {
      return this.cartItem?.quantity || 0;
    },
    hasQuantityBadge() {
      return this.displayCartQuantity && this.cartQuantity > 0;
    },
    maxQuantity() {
      return this.cartItem?.maximumAvailable || this.offer?.maxOrderableAmount || Infinity;
    },
    stockOrMaxQuantity() {
      return this.maxQuantity < this.stockQuantity ? this.maxQuantity : this.stockQuantity;
    },
    orderableQuantity() {
      // max quantity available - currently in cart - quantity to be put in the cart
      const quantity = this.stockOrMaxQuantity - this.cartQuantity - (this.quantity - 1);

      return quantity >= 0 ? quantity : 0;
    },
    inStock() {
      return this.orderableQuantity > 0;
    },
    isDisabled() {
      if (!this.cartInitialized || this.isSubmitting) {
        return true;
      }

      return !this.inStock;
    },
    buttonTitle() {
      if (!this.inStock) {
        return 'Dieser Artikel ist aktuell leider nicht verfügbar';
      }

      if (this.quantity === 1) {
        return 'Artikel in den Warenkorb legen';
      }

      return `${this.quantity} Artikel in den Warenkorb legen`;
    },
    tealiumRel() {
      return this.tealiumSuffix
        ? `content.button.in-den-warenkorb.${this.tealiumSuffix}`
        : 'content.button.in-den-warenkorb';
    },
  },
  watch: {
    offer(offer) {
      this.stockQuantity = getStockFromOffer(offer);
    },
  },
  methods: {
    ...mapActions('cart', ['addCartItem']),
    click() {
      this.$refs.button.click();
      this.$emit('click');
    },
    trackAddToCart(item) {
      // @TODO: move tracking into store or abstraction layer
      try {
        this.trackAction({
          wt_link_id: this.tealiumRel,
          event_name: 'cart_add',
          ...serializeCartAction(undefined, [item], false),
        });
      } catch (error) {
        // do nothing, no error reporting on tracking
      }
    },
    async addOfferToCart() {
      this.isSubmitting = true;
      try {
        const { item } = await this.addCartItem({
          quantity: this.quantity,
          articleNumber: this.articleNumber,
          contractNumber: this.contractNumber,
        });

        this.trackAddToCart(item);
        this.isSubmitting = false;
        this.$emit('added', item);
        await this.$dialog.show('AddToCartDialogContent', {
          type: 'success',
          componentOptions: { item },
        });
      } catch (error) {
        this.isSubmitting = false;
        this.$emit('error');

        await this.handleAddToCartError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    handleAddToCartError(error) {
      if (this.hasError(error, 'stock', 'greater_than')) {
        // If the stock returned by the Brodos live request is 0 the user gets
        // the out of stock message. The field name is quite weird imho.
        this.stockQuantity = 0;
        return this.$dialog.info('Dieser Artikel ist derzeit nicht auf Lager.', { type: 'error' });
      }

      if (this.hasError(error, 'base', 'max_value')) {
        // If the cart total sum is greater than 2500 Euro.
        return this.$dialog.info(
          'Der Warenkorb übersteigt den zulässigen Wert von 2499 €. Größere Bestellungen müssen auf mehrere Vorgänge verteilt werden.',
          { type: 'error' }
        );
      }

      return this.$dialog.info(
        'Hoppla! Da ist etwas schiefgelaufen. Bitte versuchen Sie es noch einmal.',
        { type: 'error' }
      );
    },
    hasError(error, field, key) {
      if (!error?.data?.errors) {
        return false;
      }
      const fieldErrors = error.data.errors?.[field] || [];

      return fieldErrors.some((item) => item.error === key);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.add-to-cart-button-container {
  &__count {
    overflow: visible;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}
</style>
