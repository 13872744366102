import { flatMap, isArray, isFunction } from 'lodash-es';
import { formatPrice } from '@i22/rocket';

const ITEM_SEPARATOR = ';';
export const CONTRACT_DURATION_MONTHS = 24;

function sanitizeItemName(name) {
  return name.toLowerCase().replace(';', '');
}

const SOLODEVICE_PATHS = [
  'smartphones-und-handys',
  'tablets-und-surfsticks/surfsticks',
  'tablets-und-surfsticks/tablets',
];

const fields = {
  shop_customer_lifetime_value({ totalOrderValue, totalOnetimePrice }) {
    // How does it differ from shopOrderValue => coupons + reduced amount
    if (totalOrderValue) return formatPrice(totalOrderValue);
    if (totalOnetimePrice) return formatPrice(totalOnetimePrice);
    return undefined;
  },
  shop_customer_type({ customerIdentified }) {
    return customerIdentified ? 'bestandskunde' : 'nicht-bestandskunde';
  },
  shop_product_business_case({ items }) {
    return items.map(() => 'neugeschaeft');
  },
  shop_order_id({ orderId }) {
    return orderId;
  },
  shop_order_value({ totalOnetimePrice }) {
    return totalOnetimePrice ? formatPrice(totalOnetimePrice) : undefined;
  },
  shop_payment_mode({ paymentType }) {
    return paymentType;
  },
  shop_product_bundle({ items }) {
    return items.filter((item) => item.bundle).map((item) => item.name);
  },
  shop_product_category({ items }) {
    return items.map((item) => {
      // item.shopProductCategory is only set in cart -> lib/tealium/serializers/cart.js
      if (item.shopProductCategory) {
        return item.shopProductCategory;
      }
      // for all others
      if (SOLODEVICE_PATHS.includes(item.primaryCategory.path)) {
        return 'endgeraet';
      }
      return 'zubehoer';
    });
  },
  shop_product_class({ items }) {
    return items.map((item) => {
      // item.shopProductCategory is only set in cart -> lib/tealium/serializers/cart.js
      if (item.shopProductCategory) {
        return `${item.shopProductCategory} solo`;
      }
      // for all others
      if (SOLODEVICE_PATHS.includes(item.primaryCategory.path)) {
        return 'endgeraet solo';
      }
      return 'zubehoer solo';
    });
  },
  shop_product_duration({ items }) {
    return items.map((item) => {
      // FIXME: Replace this with the real Goliath value
      if (item.contractNumber) return CONTRACT_DURATION_MONTHS.toString();
      return '1';
    });
  },
  shop_product_family({ items }) {
    return items.map((item) => sanitizeItemName(item.familyName));
  },
  shop_product_manufacturer_sku({ items }) {
    return flatMap(items, (item) => item.eans);
  },
  shop_product_name({ items }) {
    return items.map((item) => sanitizeItemName(item.name));
  },
  shop_product_price({ items }) {
    return items.map((item) => formatPrice(item.price));
  },
  shop_product_quantity({ items }) {
    return items.map((item) => item.quantity);
  },
  shop_product_sku({ items }) {
    return items.map((item) => item.articleNumber || item.contractNumber);
  },
  shop_product_status({ action }) {
    return action;
  },
};

/*
  Usage:

      serializeAction({
        action: 'add',
        totalOnetimePrice: 20.42,
        totalOrderValue: 15.95,
        orderId: '12345',
        paymentType: 'paypal',
        customerIdentified: false,
        items: [
          {
            name: 'Amazon Echo Dot Schwarz',
            articleNumber: '99912345',
            ean: '888123455',
            contractNumber: null,
            quantity: 2,
            price: 12.34,
          },
          {
            name: 'Amazon Echo Dot Schwarz',
            articleNumber: '99912346',
            ean: '888123455',
            contractNumber: null,
            quantity: 2,
            price: 12.34,
          },
          {
            name: 'Magenta SmartHome Basic',
            articleNumber: null,
            ean: null,
            contractNumber: '8912312',
            quantity: 1,
            price: 6.66,
          },
        ],
      });
*/

export default function serializeAction(options = {}) {
  return Object.keys(fields).reduce((data, field) => {
    const helper = fields[field];
    let value;
    if (helper) {
      if (isFunction(helper)) {
        value = helper(options);
      } else {
        value = helper;
      }
      if (isArray(value)) value = value.join(ITEM_SEPARATOR);
    }
    value = value || '';
    return { ...data, [field]: value };
  }, {});
}
