async function track() {
  // In TSHT we do not have Webtrekk / Tealium installed.
  // This is only to match with components from the other systems.
}

export default {
  view(data) {
    return track('view', data);
  },
  link(data) {
    return track('link', data);
  },
  updateAutomaticLinkTracking() {
    if (window.wt) {
      window.wt.linkTrackInit();
    }
    if (window.wtm) {
      window.wtm.linkTrackInit();
    }
  },
};
